
/* Style the tab */
.tab {
    
    border: 1px solid #ccc;
    background-color: #f1f1f1;
  }
  table, th, td {
    border: 1px solid #d5d5d5;
  }
  /* Style the buttons inside the tab */
  .tab button {
    display: block;
    background-color: #fff;
    color: black;
    padding: 15px 16px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    border-bottom: 1px solid #ccc;
    font-size: 17px;
  }
  .width_30{
    width:25%;
  }
  /* Change background color of buttons on hover */
  .tab button:hover {
    background-color: #ddd;
  }
  
  /* Create an active/current "tab button" class */
  .tab button.active {
    background-color: #2768b0;
    color: #fff;
    position:relative;
    
  }
  .avatar_image_width{
    width:130px;
  }
  .tab button.active::before{
    content: "";
    position: absolute;
    border-style: solid;
    border-color: #ff000000 #00800000 #0000ff00 #2768b0;
    right: -33px;
    top: 17%;
    border-width: 20px;
  }
  
  .tab button.active {
    background-color: #2768b0;
    color: #fff;
  }
  
  /* Style the tab content */
  .tabcontent {
    padding: 0px 12px;
    border-left: none;
    width:75%;
  }