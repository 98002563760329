.sidebar {
  width: 200px;
  height: 100vh;
  background-color: #333;
  color: #fff;
  transition: width 0.5s ease;
  float: left ;
}

.sidebar.closed {
  width: 75px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}


.sidebar ul li a { 
  color: #fff;
  text-decoration: none;
}

.sidebar ul li a:hover {
  color: #00bcd4;
}

.toggle-btn {
  background-color: transparent;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
}

/* Rest of the CSS remains the same */

.sidebar ul {
  list-style-type: none;
  padding: 0;
}



.sidebar ul li a {
  color: #fff;
  text-decoration: none;
}

.sidebar ul li a:hover {
  color: #00bcd4;
}

/* Navbar Styles */
/* .navbar {
  background-color: #00bcd4;
  color: #fff;
  height: 60px;
  line-height: 60px;
} */

.logo {
  float: left;
  margin-left: 20px;
}

.user-info {
  float: right;
  margin-right: 20px;
}

/* Content Styles */

/* Page Styles */
.page {
  padding: 20px;
}

/* Global Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}
