$body-bg: #edf2f9;

.navbar-vertical-collapsed .content {
  margin-left: 5.75rem;
}
.message {
  margin-bottom: 1.25rem;
  font-size: .875rem;
}
.g-0.h-100 {
  height: 100vh!important;
}

.bgcolornew{
  background-color: rgba(54, 92, 245, 0.1);
}

.new_css{
  font-size: 15px;
  color: #636363;
  font-weight: 400;

}
.pt-40 {
  padding-top: 40px;
}
.text-gray {
  color: #5d657b !important;
}
.text-sm {
  font-size: 14px;
  line-height: 22px;
}
.text-medium {
  font-weight: 500;
}
.text-center {
  text-align: center !important;
}
.pb-40 {
  padding-bottom: 40px;
}
.pt-40 {
  padding-top: 40px;
}
.signin-wrapper .singin-option button {
  padding-left: 30px;
  padding-right: 30px;
}
.signin-wrapper .singin-option button {
  font-size: 16px;
  font-weight: 600;
}
.main-btn i {
  font-size: 17px;
}
.mr-10 {
  margin-right: 10px;
}
.main-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 8px 20px;
  font-weight: 500;
  border-radius: 5px;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  z-index: 5;
  transition: all 0.4s ease-in-out;
  border: 1px solid transparent;
  overflow: hidden;
}

.primary-btn-outline {
  background: transparent;
  color: #365CF5;
  border-color: #365CF5;
}
.danger-btn-outline {
  background: transparent;
  color: #d50100;
  border-color: #d50100;
}
.logindetails.form-control {
  width: 100%;
  background: rgb(239 239 239);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 6px;
  color: #5d657b;
  resize: none;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
}
.message .message-content {
  padding: 9px;
  background-color: #f5f6fa;
  color: #8094ae;
  border-radius: 5px;
  text-align: left;
  display: inline-block;
  max-width: 25rem;
}
.message.self .message-content {
  background-color: #665dfe;
  color: #fff;
  margin-left: 0;
  margin-right: 1.25rem;
}
.message .message-content{
  .superadmin{
    display: block;
    color:#000;
    font-weight: bold;
  }
}
.margin-bottom p{
  margin-bottom: 0px;
}
.message.self {
  text-align: right;
}
  .swal2-popup{
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    &:before{
      content: "";
      position: absolute;
      top:0px;
      background:linear-gradient(rgb(21 61 105 / 46%), rgb(21 61 105)),url("../src/assets/img/investment.png");
      left: 0px;
      width: 100%;
      height: 100%;
    }
  }
.capitalizes {
  text-transform: capitalize;
}
.action_button button {
  align-items: center;
  background: var(--i);
  border: none;
  border-radius: 5px;
  box-shadow: none;
  color: #fff;
  display: flex;
  height: 30px;
  justify-content: center;
  padding: 2px;
  width: 30px;
}
.navbar-vertical-collapsed-hover .navbar-collapse {
  box-shadow: 0.625rem 0 0.625rem -0.5625rem rgba(0, 0, 0, 0.2);
}
.nav-item.new_menu.active > .dropdown_menu{
  background-color: #007bff;
  color: #fff!important;
}

.nav-item.new_menu.active a.nav-link {
  background-color: #007bff;
  color: #fff!important;
  border-radius: 5px;
  padding-left: 5px;
}

.nav-item.new_menu.active .menulinkactive{
  background-color: #007bff;
  color: #fff!important;
}

.nav-item.active .dropdown-indicator {
  background-color: #468be8;
    color: #ffffff !important;
}
.nav-item.active .dropdown-indicator:after{
  border-right: 1px solid #fff!important;
  border-bottom: 1px solid #fff!important;
}
.navbar-vertical-collapsed-hover .navbar-collapse {
  width: 12.625rem !important;
}

.content {
  margin-left: 15.625rem;
}

.navbar-top.navbar-glass-shadow {
  position: sticky;
  top: 0px;
}

body {
  background: $body-bg;
}

a,
a:hover {
  text-decoration: none !important;
}

.imagbeprofile {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.buttonfordownload {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  align-items: center;
  background: #fff;
  border: 1px solid #e8ebed;
  border-radius: 8px;
  display: flex;
  font-size: 18px;
  height: 38px;
  justify-content: center;
  -webkit-justify-content: center;
  padding: 6px;
  width: 38px;
}

.waviy {
  position: relative;
  -webkit-box-reflect: below -2px linear-gradient(transparent, rgba(0, 0, 0, .2));
}

.waviy img {
  position: relative;
  display: inline-block;
  font-size: 40px;
  color: #000;
  text-transform: uppercase;
  animation: waviy 5s infinite;
  animation-delay: 0.9s;
}

@keyframes waviy {
  0% {
    transform: rotateY(0deg);
  }

  25% {
    transform: rotateY(360deg);
  }

  50% {
    transform: rotateY(0deg);
  }

  75% {
    transform: rotateY(360deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}

#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  background-color: #fff;
}

#preloader {
  background-color: #edf2f9;
  height: 100%;
  width: 100%;
  position: fixed;
  margin-top: 0px;
  top: 0px;
  z-index: 999;
}

#loading-center {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  position: relative;

  border-radius: 50%;
  margin: 75px;
  display: inline-block;
  vertical-align: middle;
}

.loader .loader-outter {
  position: absolute;
  border: 4px solid var(--tg-primary-color);
  border-left-color: transparent;
  border-bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: loader-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
  animation: loader-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
}

.loader .loader-inner {
  position: absolute;
  border: 4px solid var(--tg-primary-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  border-right: 0;
  border-top-color: transparent;
  -webkit-animation: loader-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
  animation: loader-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
}

@-webkit-keyframes loader-outter {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-outter {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-inner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes loader-inner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

// loder end 
.aboutusimage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-upload {
  position: relative;
  max-width: 205px;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input+label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #FFFFFF;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input+label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.avatar-upload .avatar-edit input+label:after {
  // content: "\f040";
  font-family: 'FontAwesome';
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-upload .avatar-preview {
  width: 120px;
  height: 120px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #F8F8F8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  margin: auto;
}

.avatar-upload .avatar-preview>div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

// profile edit 

.editable-cell {
  transition: .3s all linear;
}

.editable-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editable-input {
  border: none;
  font-size: inherit;
  background: #e8e8e8c4;
  color: inherit;
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0px 0px;
  transition: .3s all linear;

}

.editable-input:focus {
  outline: none;
  background: white;
  color: #545454;
}

.edit-button {
  cursor: pointer;
  font-size: 15px;
  position: relative;
  top: 5px;
}

.hide {
  visibility: hidden !important;
}

.editing {
  background: #E9F5FA;
  color: #f1f1f1;
  box-shadow: -2px 0px 0px #0081C6;
}

// top mav bar 
.vertical-tabs {
  display: flex;
}

.tab {
  background-color: #f1f1f1;
}

.tab button {
  display: block;
  background-color: #fff;
  color: black;
  padding: 15px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  border-bottom: 1px solid #ccc;
  font-size: 17px;
}

.tab button:hover {
  background-color: #ddd;
}

.tab button.active {
  background-color: #2768b0;
  color: #fff;
}

.navbar-top {
  background: #edf2f9;
}

.tabcontent {
  display: none;
  padding: 20px;
  border-left: 1px solid #ccc;

}

.skale_img {
  width: 90px;
  cursor: pointer;

  &:hover {
    transform: scale(3);
    transition: 0.2s ease-in-out;
    border-radius: 8px;
    overflow: scroll;
  }
}

.tabcontent p {
  margin: 0;
}

.dfsdf {
  margin-right: 10px;
}

.hoverable {
  border-radius: 8px;
  &:hover {
    transform: scale(1.5);
    transition: 0.5s ease-in-out;
  }
}
.scrollable {
  max-height: 400px; /* Adjust this value as needed */
  overflow-y: auto;
}


input[type="date"] {
  border-radius: 5px;
  // border: 1px solid #88abd4;
  padding: 5px;
  // background: aliceblue;
}

.react-datepicker-wrapper {
  width: 100%;
}

// ****************
/* Style the tab */
.tab {

  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  display: block;
  background-color: #fff;
  color: black;
  padding: 15px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  border-bottom: 1px solid #ccc;
  font-size: 17px;
}

.width_30 {
  width: 25%;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current "tab button" class */
.tab button.active {
  background-color: #2768b0;
  color: #fff;
  position: relative;

}

.avatar_image_width {
  width: 130px;
}

.tab button.active::before {
  content: "";
  position: absolute;
  border-style: solid;
  border-color: #ff000000 #00800000 #0000ff00 #2768b0;
  right: -33px;
  top: 17%;
  border-width: 20px;
}

.tab button.active {
  background-color: #2768b0;
  color: #fff;
}

/* Style the tab content */
.tabcontent {
  padding: 0px 12px;
  border-left: none;
  width: 75%;
}

// ****************
.sidebar {
  width: auto;
  height: 100vh;
  background-color: #edf2f9;
  color: #fff;
  transition: width 0.5s ease;
  float: left;
}

.sidebar.closed {
  width: 75px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}


.sidebar ul li a {
  color: #fff;
  text-decoration: none;
}

.sidebar ul li a:hover {
  color: #00bcd4;
}

.toggle-btn {
  background-color: transparent;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
}

/* Rest of the CSS remains the same */

.sidebar ul {
  list-style-type: none;
  padding: 0;
}



.sidebar ul li a {
  color: #5e6e82;
  text-decoration: none;
}

.sidebar ul li a:hover {
  color: #00bcd4;
}

/* Navbar Styles */
// .navbar {
//   background-color: #00bcd4;
//   color: #fff;
//   height: 60px;
//   line-height: 60px;
//   width: 100%;
// }
.navbar-top {
  width: 100%;
}

.logo {
  float: left;
  margin-left: 20px;
}

.user-info {
  float: right;
  margin-right: 20px;
}

/* Content Styles */


/* Page Styles */
.page {
  padding: 20px;
}



// start css  global 
.icon_size {
  font-size: 30px;
}

div.navbar-vertical-label {
  color: #8794a4;
}



.form-control:hover,
.form-control:focus,
.form-control:active {
  box-shadow: none !important;
  outline: none !important;
}
.form_details {
  .form-group.local-forms {
    margin-bottom: 8px;
  }
  .w-100.form-control {
    height: auto;
  }
  .local-forms {
    position: relative;
  }
  .local-forms label {
    font-size: 13px;
    color: #ababab;
    font-weight: 500;
    position: absolute;
    top: -10px;
    left: 10px;
    background: #fff;
    margin-bottom: 0;
    padding: 0px 5px;
    z-index: 99;
  }
  .login-danger,
  .star-red {
    color: #ff0000;
  }
  .form-control {
    border: 1px solid #ddd;
    box-shadow: none;
    color: #333;
    font-size: 15px;
    height: 45px;
  }
}
